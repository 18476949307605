<script lang="ts" setup>
import { NuxtImg } from "#components";

import type { HTMLProps } from "../types-utils";

type NuxtImgProps = InstanceType<typeof NuxtImg>["$props"] & { srcset?: string; fetchpriority?: string };
const props = withDefaults(
	defineProps<
		{
			sources?: Array<Record<string, string | number>>;
			imgAttrs?: NuxtImgProps;
			background?: string;
		} & HTMLProps
	>(),
	{ background: "" }
);
const background = computed(() => props.background);
</script>
<template>
	<section>
		<picture>
			<template v-if="sources">
				<source v-for="src in sources" :key="src.srcset" v-bind="src" />
			</template>
			<NuxtImg v-if="imgAttrs" v-bind:="imgAttrs" />
		</picture>
		<div>
			<slot />
		</div>
	</section>
</template>

<style lang="scss">
:root {
	--a-banner-default-height: 310px;
	--a-banner-default-border-radius: 12px;
	--a-banner-default-before-display: block;
	--a-banner-default-picture-left-position: auto;
	--a-banner-default-picture-right-position: 0;
	--a-banner-default-picture-bottom-position: 0;
	--a-banner-default-picture-transform: 50%;
	--a-banner-default-image-width: 100%;
	--a-banner-default-image-height: 100%;
	--a-banner-default-image-object-fit: cover;
	--a-banner-default-pictute-height: 100%;
	--a-banner-default-overflow: hidden;
	--a-banner-default-picture-top-position: 0;
	--a-banner-default-image-radius: 0;
	--a-banner-default-pictute-width: auto;

	@include media-breakpoint-down(md) {
		--a-banner-default-image-width: 328px;
		--a-banner-default-height: 273px;
		--a-banner-default-picture-right-position: 50%;
		--a-banner-default-image-height: auto;
		--a-banner-default-picture-bottom-position: auto;
		--a-banner-default-pictute-height: auto;
	}
}

.tao:root {
	--a-banner-default-height: 332px;
	--a-banner-default-border-radius: 20px;
	--a-banner-default-before-display: none;
	--a-banner-default-picture-left-position: auto;
	--a-banner-default-picture-transform: 50%;
	--a-banner-default-picture-top-position: -22px;

	@include media-breakpoint-down(md) {
		--a-banner-default-height: 394px;
		--a-banner-default-image-width: 330px;
		--a-banner-default-border-radius: 10px;
	}
}

.nlc:root {
	--a-banner-default-height: 415px;
	--a-banner-default-before-display: none;
	--a-banner-default-border-radius: 16px;
}

.fortuneWheelz {
	--a-banner-default-border-radius: 16px;
	--a-banner-default-height: 312px;
	--a-banner-default-pictute-height: 344px;
	--a-banner-default-overflow: visible;
	--a-banner-default-picture-top-position: auto;
	--a-banner-default-image-radius: 16px;

	@include media-breakpoint-down(md) {
		--a-banner-default-image-width: auto;
		--a-banner-default-pictute-height: auto;
		--a-banner-default-height: 384px;
		--a-banner-default-overflow: hidden;
		--a-banner-default-picture-top-position: 0;
		--a-banner-default-picture-bottom-position: 0;
	}
}

.funzCity:root {
	--a-banner-default-height: 350px;
}
</style>

<style lang="scss" scoped>
section {
	border-radius: var(--a-banner-default-border-radius);
	overflow: var(--a-banner-default-overflow);
	height: var(--a-banner-default-height);
	width: 100%;
	position: relative;
	background: v-bind(background);

	& > picture {
		display: flex;
		position: absolute;
		top: var(--a-banner-default-picture-top-position);
		left: var(--a-banner-default-picture-left-position);
		right: var(--a-banner-default-picture-right-position);
		width: auto;
		bottom: var(--a-banner-default-picture-bottom-position);
		height: var(--a-banner-default-pictute-height);

		@include media-breakpoint-down(md) {
			transform: translate(var(--a-banner-default-picture-transform), 0);
			right: var(--a-banner-default-picture-right-position);
		}

		img {
			width: var(--a-banner-default-image-width);
			height: var(--a-banner-default-image-height);
			object-fit: var(--a-banner-default-image-object-fit);
			border-radius: var(--a-banner-default-image-radius);
		}
	}

	& > div {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
	}
}
</style>
